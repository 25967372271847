import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/abdullatieffathoni/Code/javascript/tds-web/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Button, Card } from 'tunggal';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageDescription mdxType="PageDescription">
      <p><inlineCode parentName="p">{`Button`}</inlineCode>{` Sebuah unsur UI yang memungkinkan user untuk berinteraksi dan mengambil tindakan dalam batasan tertentu dengan satu klik.`}</p>
    </PageDescription>
    <h2>{`Variant`}</h2>
    <Row mdxType="Row">
  <Column mdxType="Column">
    <Card bordered mdxType="Card">
      <Button color="neutral" style={{
            marginRight: 16
          }} mdxType="Button">
        Basic
      </Button>
      <Button color="neutral" icon="add" iconOnly style={{
            marginRight: 16
          }} mdxType="Button">
        Icon
      </Button>
      <Button color="neutral" icon="add" style={{
            marginRight: 16
          }} mdxType="Button">
        With Icon
      </Button>
      <Button color="neutral" iconRight icon="add" style={{
            marginRight: 16
          }} mdxType="Button">
        Icon Right
      </Button>
      <Button color="primary" outline flat style={{
            marginRight: 16
          }} mdxType="Button">
        Outline
      </Button>
      <Button color="light" flat size="md" mdxType="Button">
        Link
      </Button>
    </Card>
  </Column>
    </Row>
    <h2>{`Color`}</h2>
    <Card bordered mdxType="Card">
  <Button color="neutral" style={{
        marginRight: 16
      }} mdxType="Button">
    Neutral
  </Button>
      <Button color="primary" style={{
        marginRight: 16
      }} mdxType="Button">
  Primary
      </Button>
      <Button color="secondary" style={{
        marginRight: 16
      }} mdxType="Button">
  Secondary
      </Button>
      <Button color="approval" style={{
        marginRight: 16
      }} mdxType="Button">
  Approval
      </Button>
      <Button color="danger" style={{
        marginRight: 16
      }} mdxType="Button">
  Danger
      </Button>
  <Button color="light" style={{
        marginRight: 16
      }} mdxType="Button">
    Light
  </Button>
    </Card>
    <h2>{`Size`}</h2>
    <Card bordered mdxType="Card">
      <Button color="neutral" size="sm" style={{
        marginRight: 16
      }} mdxType="Button">
  Small
      </Button>
      <Button color="neutral" size="md" style={{
        marginRight: 16
      }} mdxType="Button">
  Medium
      </Button>
      <Button color="neutral" size="lg" style={{
        marginRight: 16
      }} mdxType="Button">
  Large
      </Button>
      <Button color="neutral" size="xl" style={{
        marginRight: 16
      }} mdxType="Button">
  Extra Large
      </Button>
    </Card>
    <h2>{`State`}</h2>
    <Card bordered mdxType="Card">
  <Button color="neutral" style={{
        marginRight: 16
      }} mdxType="Button">
    Default
  </Button>
      <Button disabled color="neutral" style={{
        marginRight: 16
      }} mdxType="Button">
  Disabled
      </Button>
      <Button loading color="neutral" style={{
        marginRight: 16
      }} mdxType="Button">
  Loading
      </Button>
    </Card>
    <h2>{`Example`}</h2>
    <h3>{`Button with Icon`}</h3>
    <Card bordered mdxType="Card">
  <Button color="neutral" icon="plus" size="md" type="button" style={{
        marginRight: 16
      }} mdxType="Button">
    Add New
  </Button>
      <Button color="primary" icon="floppy-disk" size="md" type="button" style={{
        marginRight: 16
      }} mdxType="Button">
  Save Changes
      </Button>
      <Button color="secondary" icon="shopping-cart" size="md" type="button" style={{
        marginRight: 16
      }} mdxType="Button">
  Buy Now
      </Button>
      <Button color="approval" icon="tick-circle" size="md" type="button" style={{
        marginRight: 16
      }} mdxType="Button">
  Approve
      </Button>
  <Button color="danger" icon="trash" size="md" type="button" style={{
        marginRight: 16
      }} mdxType="Button">
    Delete
  </Button>
    </Card>
    {
      /* ## Code */
    }
    {
      /* ```markdown path=Button.js src=https://gotostoryboard.io
      import { Button } from "tunggal"
      <Button
       color="neutral"
       size="sm"
       type="button">
       Button
      </Button>
      ``` */
    }

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      